
import Alert from "react-bootstrap/Alert";

import { TeeTimeBooking, TeeTimeBookingStatus } from './types';
import Booking from './Booking';

type UpcomingBookingsProps = {
  teeTimes: TeeTimeBooking[]
  loading: boolean
  deleteTeeTimeBooking?: ((booking: TeeTimeBooking) => void) | undefined
}

function UpcomingBookings(props: UpcomingBookingsProps) {
  return (
    <div>
      {props.loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {props.teeTimes.length === 0 && (
            <Alert variant="info">
              No tee times yet! Book one above!
            </Alert>
          )}
          {props.teeTimes && props.teeTimes.map((item: TeeTimeBooking) => {
            return item.status === TeeTimeBookingStatus.PENDING
              ? <Booking booking={item} onDelete={() => props.deleteTeeTimeBooking?.(item)} />
              : <Booking booking={item} onDelete={() => props.deleteTeeTimeBooking?.(item)} />
          })}
        </div>
      )}
    </div>
  );
}

export default UpcomingBookings;
