
import React from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ForeClient from './ForeClient';
import { TeeTimeBooking, TeeTimeBookingStatus } from './types';
import TeeTimeScheduler from './TeeTimeScheduler';
import UpcomingBookings from './UpcomingBookings';

function Home() {

  const [pendingBookings, setPendingBookings] = React.useState<TeeTimeBooking[]>([]);
  const [actualBookings, setActualBookings] = React.useState<TeeTimeBooking[]>([]);
  const [loading, setLoading] = React.useState(true);

  let deleteTeeTimeBooking = async (booking: TeeTimeBooking) => {
    try {
      let response = await ForeClient.deleteTeeTimeBooking(booking);
      if (response.status === 200) {
        pendingBookings.splice(pendingBookings.indexOf(booking), 1);
        setPendingBookings([...pendingBookings]);
      } else {
        console.log("Error deleting booking");
      }
    } catch (e) {
      console.log(e);
    }
  }

  let handleBooking = (booking: TeeTimeBooking) => {
    pendingBookings.push(booking);
    pendingBookings.sort((a: TeeTimeBooking, b: TeeTimeBooking) => {
      return new Date(b.tee_time).getTime() - new Date(a.tee_time).getTime();
    });
    setPendingBookings([...pendingBookings]);
  }

  React.useEffect(() => {
    const loadTeeTimes = async () => {
      try {
        let teeTimeBookings = await ForeClient.getTeeTimeBookings();
        let pendingBookings = teeTimeBookings.filter((booking: TeeTimeBooking) => {
          return booking.status === TeeTimeBookingStatus.PENDING;
        });
        let actualBookings = teeTimeBookings.filter((booking: TeeTimeBooking) => {
          return booking.status === TeeTimeBookingStatus.SUCCESS
            || booking.status === TeeTimeBookingStatus.FAILURE;
        });
        setPendingBookings(pendingBookings);
        setActualBookings(actualBookings);
        setLoading(false);
      }
      catch (e) {
        console.log(e);
      }
    };

    loadTeeTimes();
  }, []);

  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col sm={8} >
          <h1>Schedule a Tee Time</h1>
          <TeeTimeScheduler onBooking={handleBooking} />
        </Col>
      </Row>
      <Row className='justify-content-md-center mt-4'>
        <Col sm={8} >
          <h1>Scheduled Tee Times</h1>
          <UpcomingBookings loading={loading} teeTimes={pendingBookings} deleteTeeTimeBooking={deleteTeeTimeBooking} />
        </Col>
      </Row>
      {actualBookings.length > 0 && (
      <Row className='justify-content-md-center mt-4'>
        <Col sm={8} >
          <h1>Tee Times</h1>
          <UpcomingBookings loading={loading} teeTimes={actualBookings} />
        </Col>
      </Row>
      )}
    </Container>
  )
}

export default Home;
