import { DateTime, DurationLike } from 'luxon';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { TeeTimeBooking, TeeTimeBookingStatus } from './types';

type BookingProps = {
  booking: TeeTimeBooking
  onDelete?: ((booking: TeeTimeBooking) => void) | undefined
}

let formatBookingDate = (dateString: string) => {
  let date = DateTime.fromISO(dateString);
  date = date.minus({days: 5});
  date = date.set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
  return date.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
}

let formatDate = (dateString: string, minus?: DurationLike) => {
  let date = DateTime.fromISO(dateString);
  if (minus) {

  }
  return date.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
}

let formatTime = (dateString: string) => {
  let date = DateTime.fromISO(dateString);
  return date.toLocaleString(DateTime.TIME_SIMPLE);
}

function Booking(props: BookingProps) {
  return (
    <Card className='mt-4 mb-4'>
      <Card.Body>
        <Card.Title>{formatDate(props.booking.tee_time)}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.booking.names.join(", ")} - {props.booking.holes} holes
        </Card.Subtitle>
        {props.onDelete && props.booking.status === TeeTimeBookingStatus.PENDING && pendingBookingContent(props)}
        {props.onDelete && props.booking.status === TeeTimeBookingStatus.FAILURE && failedBookingContent(props)}

      </Card.Body>
    </Card>
  );
}

function pendingBookingContent(props: BookingProps) {
  return (
    <>
    {props.booking.tee_time_cutoff_start !== props.booking.tee_time_cutoff_end &&
      <>
      <Card.Text>
        Booking on {formatBookingDate(props.booking.tee_time)}
      </Card.Text>
      <Card.Text>
        Will search for tee times between {formatTime(props.booking.tee_time_cutoff_start)} and
        {' '}
        {formatTime(props.booking.tee_time_cutoff_end)} if {formatTime(props.booking.tee_time)} is not available.
      </Card.Text>
      </>
    }
      <Card.Text className="text-end">
        <Button variant="danger" onClick={() => props.onDelete?.(props.booking)}>
          Cancel
        </Button>
      </Card.Text>
    </>
  )
}

function failedBookingContent(props: BookingProps) {
  return (
    <>
      <Alert variant="danger">
        This booking was scheduled, but unfortunately we were unable to make a tee time for you.
      </Alert>
    </>
  )
}

export default Booking;