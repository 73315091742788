import React from 'react';
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import { AmplifyUser } from '@aws-amplify/ui';
import ForeClient, { LinkAccountRequest } from './ForeClient';

type LinkAccountProps = {
  onLink: () => void
  user: AmplifyUser | undefined
}

function LinkAccount(props: LinkAccountProps) {

  let [inititalLoading, setInitialLoading] = React.useState<boolean>(false)
  let [username, setUsername] = React.useState<string>('')
  let [password, setPassword] = React.useState<string>('')
  let [loading, setLoading] = React.useState<boolean>(false)
  let [validated, setValidated] = React.useState<boolean>(false)
  let [credentialsError, setCredentialsError] = React.useState<boolean>(false)

  let onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    let form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setCredentialsError(false)
    if (form.checkValidity() === true) {
      setValidated(false)
      setLoading(true)
      let request: LinkAccountRequest  = {
        username: username,
        password: password
      }

      try {
        let success = await ForeClient.linkAccount(request)
        setLoading(false)
        if (success) {
          props.onLink()
        } else {
          setCredentialsError(true)
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setValidated(true)
    }
  };

  React.useEffect(() => {
    async function checkIfLinked() {
      setInitialLoading(true)
      try {
        let linkedAccounts = await ForeClient.getLinkedAccounts()
        setInitialLoading(false)
        if (linkedAccounts.length > 0) {
          props.onLink()
        }
      } catch (e) {
        console.log(e)
      }
    }

    checkIfLinked()
  }, [props])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1>Link your account</h1>
          <p>
            To use this app, you must link your account with your TeeOn account.
            This is a one-time process that will allow you to schedule tee times.
          </p>
          <p>
            We store your TeeOn credentials in a secure manner and never share them
            other than for making tee times with TeeOn.
          </p>
        </Col>
      </Row>
      {credentialsError && (
      <Row className="justify-content-center">
        <Col md={6}>
        <Alert variant="danger">
          We were unable to login to TeeOn using your username and password. <br/>
          Please ensure these are correct and try again.
        </Alert>
        </Col>
      </Row>
      )}
      {inititalLoading ? (
        <Row className="justify-content-center text-center mt-4">
          <Col md={6} className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
            <span className='ms-4'> Checking if your account is linked...</span>
          </Col>
        </Row>
      ) : (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Row className="justify-content-center mt-4">
            <Form.Group as={Col} md={3} controlId="formBasicEmail">
              <Form.FloatingLabel label="TeeOn Username">
                <Form.Control
                  required={true}
                  type="text"
                  value={username}
                  disabled={loading}
                  onChange={(event) => setUsername(event.target.value)} />
              </Form.FloatingLabel>
            </Form.Group>
            <Col md={3}/>
          </Row>
          <Row className="justify-content-center mt-2">
            <Form.Group as={Col} md={3} controlId="formBasicPassword">
              <Form.FloatingLabel label="TeeOn Password">
                <Form.Control
                  required={true}
                  type="password"
                  value={password}
                  disabled={loading}
                  onChange={(event) => setPassword(event.target.value)} />
              </Form.FloatingLabel>
            </Form.Group>
            <Col md={3}/>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col md={6}>
              <Button variant="primary" disabled={loading} type="submit">
                {loading
                  ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span> Linking your account...</span>
                    </>
                  )
                  : (
                    <span>Link Account</span>
                  )
                }
              </Button>

            </Col>
          </Row>
        </Form>
      )}
    </Container>
  )
}

export default LinkAccount;