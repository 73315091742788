export type LinkedAccount = {
  entity_type: string,
  entity_id: string,
  user_id: string,
}

export enum TeeTimeBookingStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type TeeTimeBooking = {
  entity_type: string,
  entity_id: string,
  user_id: string,
  tee_time: string,
  tee_time_cutoff_start: string,
  tee_time_cutoff_end: string,
  linked_account_entity_id: string,
  names: [string],
  holes: number,
  course_id: string,
  status: TeeTimeBookingStatus,
  booked_tee_time: string,
}
