import { Auth } from 'aws-amplify';
import { LinkedAccount, TeeTimeBooking } from './types';

let BASE_URL = 'https://api.bookme.golf'

function ForeClient() {

}

ForeClient.createTeeTimeBooking = async (request: any): Promise<TeeTimeBooking> => {
  let jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    body: JSON.stringify(request)
  };
  const response = await fetch(BASE_URL + '/tee-time-bookings', requestOptions);

  if (response.status === 200) {
    const data = await response.json();
    return data.teeTimeBooking
  } else {
    throw new Error("Error booking tee time");
  }
};

ForeClient.deleteTeeTimeBooking = async (teeTimeBooking: TeeTimeBooking): Promise<Response> => {
  let jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  const requestOptions = {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    body: JSON.stringify({ "teeTimeBooking": teeTimeBooking })
  };
  return await fetch(BASE_URL + '/tee-time-bookings', requestOptions);
}

ForeClient.getTeeTimeBookings = async (futureOnly = true): Promise<TeeTimeBooking[]> => {
  let jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  console.log(jwt)
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
  };
  const response = await fetch(BASE_URL + '/tee-time-bookings?futureOnly=' + futureOnly, requestOptions);
  const data = await response.json();
  data.teeTimeBookings.sort((a: TeeTimeBooking, b: TeeTimeBooking) => {
    return new Date(b.tee_time).getTime() - new Date(a.tee_time).getTime();
  });
  return data.teeTimeBookings;
};

export type LinkAccountRequest = {
  username: string,
  password: string
}

ForeClient.linkAccount = async (request: LinkAccountRequest) => {
  let jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    body: JSON.stringify(request)
  };
  const response = await fetch(BASE_URL + '/linked-accounts', requestOptions);
  return response.status === 200;
}

ForeClient.getLinkedAccounts = async (): Promise<[LinkedAccount]> => {
  let jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  const response = await fetch(BASE_URL + '/linked-accounts', requestOptions);
  const data = await response.json();
  return data.linkedAccounts;
}

export default ForeClient;