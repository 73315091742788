// @flow
import Alert from "react-bootstrap/Alert";
import { AmplifyUser } from '@aws-amplify/ui';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';

import Home from './Home';
import LinkAccount from './LinkAccount';
import Navbar from './Navbar';

import './App.css';
import '@aws-amplify/ui-react/styles.css';


function App() {
  const [hasLinkedAccount, setHasLinkedAccount] = React.useState(localStorage.getItem('hasLinkedAccount') === 'true');
  const { authStatus } = useAuthenticator((context) => [context.authStatus])

  React.useEffect(() => {
    localStorage.setItem('hasLinkedAccount', hasLinkedAccount.toString());
  }, [hasLinkedAccount]);


  function renderApp() {
    return <Home />
  }

  function renderFirstTimeUse(user: AmplifyUser | undefined) {
    return <LinkAccount user={user} onLink={() => setHasLinkedAccount(true)} />
  }

  async function handleSignOut() {
    setHasLinkedAccount(false);
  }

  return (
    <div className="App">
      <Navbar onSignOut={handleSignOut} />
      {authStatus !== 'authenticated' && (
          <Container>
            <Row className="justify-content-center mt-4">
              <Col md={8}>
                <Alert variant="info">
                  <Alert.Heading>📣 Heads Up!</Alert.Heading>
                  <p>
                    🥇 <b>This is the first release of Book Me Golf. It's a work in progress and you should expect some issues.</b>
                  </p>
                  <p>
                    ⛳️ <b>That being said, our tee time booking process seems to be working well!</b>
                  </p>
                  <p>
                    🤔 <b>If you run into any problems let us know by clicking the Feedback button at the top!</b>
                  </p>
                  <br/>
                  <p>
                    For now everyone needs to be invited before they login. If you'd like to try it out,
                    please request an invite using <a href="https://forms.gle/9339X3ZXZxvqKQ8o9" rel="noreferrer noopener" target="_blank">this form!</a>
                  </p>
                </Alert>
              </Col>
            </Row>
          </Container>
        )}
      <div className="App-body">
        <Authenticator loginMechanisms={['email']} hideSignUp={true}>
          {({ signOut, user }) => (
            <div>
              {hasLinkedAccount && user ? renderApp() : renderFirstTimeUse(user)}
            </div>
          )}
        </Authenticator>
      </div>
    </div>
  );
}

export default App;
