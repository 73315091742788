import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import { useAuthenticator } from '@aws-amplify/ui-react';

interface NavbarProps {
    onSignOut: () => void
}

function NavBar(props: NavbarProps) {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  let handleSignOut = () => {
    signOut()
    props.onSignOut()
  }

  return (
    <Navbar bg="dark" variant="dark">
    <Container>
        <Navbar.Brand href="#home">
            <span className="me-2">⛳️</span><span>Book Me Golf</span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Button variant='secondary' href="https://forms.gle/wD59k1QYZehTo2VC6" target="_blank" rel="noreferrer noopener">
              Feedback
            </Button>
          </Navbar.Text>
          {user && (
          <Navbar.Text className="ms-4">
            <a href='#signout' onClick={handleSignOut}>Sign Out</a>
          </Navbar.Text>
          )}
        </Navbar.Collapse>
    </Container>
    </Navbar>
  );
}

export default NavBar;