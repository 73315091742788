// @flow
import { DateTime } from 'luxon';
import React, { ChangeEvent, Dispatch } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { TeeTimeBooking } from './types';

import "react-datepicker/dist/react-datepicker.css";
import ForeClient from "./ForeClient";

type TeeTimeSchedulerProps = {
  onBooking: (booking: TeeTimeBooking) => void
}

function TeeTimeScheduler(props: TeeTimeSchedulerProps) {
  // form entry fields
  let defaultDateTime = DateTime.local({zone: "America/Vancouver"}).plus({ days: 5 }).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  let [teeTime, setTeeTime] = React.useState(defaultDateTime);
  let [teeTimeCutoffStart, setTeeTimeCutoffStart] = React.useState(defaultDateTime);
  let [teeTimeCutoffEnd, setTeeTimeCutoffEnd] = React.useState(defaultDateTime);
  let [numPlayers, setNumPlayers] = React.useState(4);
  let [holes, setHoles] = React.useState(18);
  let [players, setPlayers] = React.useState(["", "", "", ""]);

  // validations
  let [validated, setValidated] = React.useState<boolean>(false);
  let [showTimeWarning, setShowTimeWarning] = React.useState<boolean>(false);
  let [showDateError, setShowDateError] = React.useState<boolean>(false);
  let dateRef = React.useRef<HTMLInputElement>(null);
  let timeRef = React.useRef<HTMLSelectElement>(null);
  let cutoffStartRef = React.useRef<HTMLSelectElement>(null);
  let cutoffEndRef = React.useRef<HTMLSelectElement>(null);

  // results
  let [submitError, setSubmitError] = React.useState(false);
  let [submitSuccess, setSubmitSuccess] = React.useState(false);
  let [loading, setLoading] = React.useState(false);

  let cedarHillTeeTimeMinutes = [
    0,
    7,
    15,
    22,
    30,
    37,
    45
  ]

  let availableTeeTimes = []

  for (var i=7; i < 19; i++) {
    for (var j=0; j < cedarHillTeeTimeMinutes.length; j++) {
      let availableTeeTime = DateTime
        .local({zone: "America/Vancouver"})
        .set({ hour: i, minute: cedarHillTeeTimeMinutes[j] })
      availableTeeTimes.push(availableTeeTime)
    }
  }

  React.useEffect(() => {
    if (teeTimeCutoffStart > teeTime) {
      setTeeTimeCutoffStart(teeTime)
    }
    if (teeTimeCutoffEnd < teeTime) {
      setTeeTimeCutoffEnd(teeTime)
    }
  }, [teeTime])

  React.useEffect(() => {
    if (teeTimeCutoffStart > teeTime) {
      cutoffStartRef.current?.setCustomValidity("Invalid")
    } else {
      cutoffStartRef.current?.setCustomValidity("")
    }
    if (teeTimeCutoffEnd < teeTime) {
      cutoffEndRef.current?.setCustomValidity("Invalid")
    } else {
      cutoffEndRef.current?.setCustomValidity("")
    }
    if (teeTime.minus({ days: 30 }) > DateTime.local({zone: teeTime.zone}).set({hour: 23})) {
      dateRef.current?.setCustomValidity("Invalid")
    } else {
      dateRef.current?.setCustomValidity("")
    }
    setShowTimeWarning(teeTime.hour <= 7 && teeTime.minute < 30)
  }, [teeTime, teeTimeCutoffStart, teeTimeCutoffEnd])


  let onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    let form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      setValidated(false);

      let formData = {
        teeTime: teeTime.toFormat("yyyy-MM-dd'T'HH:mm:ssZZ"),
        teeTimeCutoffStart: teeTimeCutoffStart.toFormat("yyyy-MM-dd'T'HH:mm:ssZZ"),
        teeTimeCutoffEnd: teeTimeCutoffEnd.toFormat("yyyy-MM-dd'T'HH:mm:ssZZ"),
        holes: holes,
        names: players,
      }

      console.log(formData)

      try {
        setLoading(true);
        let booking = await ForeClient.createTeeTimeBooking(formData);
        setSubmitError(false);
        setSubmitSuccess(true);
        setTimeout(() => setSubmitSuccess(false), 5000);
        props.onBooking(booking);
      }
      catch (e) {
        setSubmitSuccess(false);
        setSubmitError(true);
        setTimeout(() => setSubmitError(false), 5000);
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      setValidated(true);
    }
  }

  let updateNumPlayers = (event: ChangeEvent<HTMLSelectElement>) => {
    setNumPlayers(Number(event.target.value));
    let newPlayers = [...players];
    if (Number(event.target.value) < players.length) {
      newPlayers = newPlayers.slice(0, Number(event.target.value));
    }
    else {
      for (let i = players.length; i < Number(event.target.value); i++) {
        newPlayers.push("");
      }
    }
    setPlayers(newPlayers);
  }

  let updatePlayer = (index: number, value: string) => {
    let newPlayers = [...players];
    newPlayers[index] = value;
    setPlayers(newPlayers);
  }

  let handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    let date = DateTime.fromISO(event.target.value);
    setTeeTimeCutoffStart(teeTimeCutoffStart.set({ year: date.year, month: date.month, day: date.day }));
    setTeeTimeCutoffEnd(teeTimeCutoffEnd.set({ year: date.year, month: date.month, day: date.day }));
    setTeeTime(teeTime.set({ year: date.year, month: date.month, day: date.day }));
  }

  let handleTimeChange = (event: ChangeEvent<HTMLSelectElement>, state: DateTime, updater: Dispatch<DateTime> ) => {
    let time = event.target.value;
    let [hour, minute] = time.split(":");
    updater(state.set({ hour: Number(hour), minute: Number(minute) }));
  }

  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}>
      <Row>
        <Form.Group as={Col} controlId="course" className="mb-3">
          <Form.Text>
            Limited to Cedar Hill for now
          </Form.Text>
          <Form.FloatingLabel label="Course">
            <Form.Select id="course" disabled={true}>
              <option value="1">Cedar Hill</option>
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
        <Col>
          {showTimeWarning && (
            <Alert variant="warning" className='small'>
              Careful! The course might not be open at this time, but we will try to book it anyway.
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="date" className="mb-3">
          <Form.FloatingLabel label="Date">
            <Form.Control
              ref={dateRef}
              id="date"
              type="date"
              value={teeTime.toFormat("yyyy-MM-dd")}
              onChange={handleDateChange} />
              <Form.Control.Feedback type="invalid">
                For now, tee times are limited to the next 30 days
              </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="time" className="mb-3">
          <Form.FloatingLabel label="Time">
            <Form.Select
              id="time"
              ref={timeRef}
              value={teeTime.toFormat("HH:mm")}
              onChange={(event) => handleTimeChange(event, teeTime, setTeeTime)}>
              {availableTeeTimes.map((availableTeeTime) => <option value={availableTeeTime.toFormat("HH:mm")}>{availableTeeTime.toLocaleString(DateTime.TIME_SIMPLE)}</option>)}
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Text>
            We will try to make a tee time for {teeTime.toLocaleString(DateTime.TIME_SIMPLE)}, but if that's unavailable we will search for tee times between the
            cutoff start and cutoff end times.
          </Form.Text>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="cutoffStart" className="mb-3">
          <Form.FloatingLabel label="Cutoff Start">
            <Form.Select
              ref={cutoffStartRef}
              id="cutoffStart"
              value={teeTimeCutoffStart.toFormat("HH:mm")}
              onChange={(event) => handleTimeChange(event, teeTimeCutoffStart, setTeeTimeCutoffStart)}>
                {availableTeeTimes.map((availableTeeTime) => <option value={availableTeeTime.toFormat("HH:mm")}>{availableTeeTime.toLocaleString(DateTime.TIME_SIMPLE)}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Cannot be after tee time
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="cutoffEnd" className="mb-3">
          <Form.FloatingLabel label="Cutoff End">
            <Form.Select
              ref={cutoffEndRef}
              id="cutoffEnd"
              value={teeTimeCutoffEnd.toFormat("HH:mm")}
              onChange={(event) => handleTimeChange(event, teeTimeCutoffEnd, setTeeTimeCutoffEnd)}>
                {availableTeeTimes.map((availableTeeTime) => <option value={availableTeeTime.toFormat("HH:mm")}>{availableTeeTime.toLocaleString(DateTime.TIME_SIMPLE)}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Cannot be before tee time
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="holes" className="mb-3">
          <Form.FloatingLabel label="Holes">
            <Form.Select id="holes" value={holes} onChange={(event) => setHoles(Number(event.target.value))}>
              <option value="18">18</option>
              <option value="9">9</option>
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="numPlayers" className="mb-3">
          <Form.FloatingLabel label="Players">
            <Form.Select id="numPlayers" value={numPlayers} onChange={updateNumPlayers}>
              <option value="4" >4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
      </Row>
      <Row>
        {players.map((player, index) => (
          <Form.Group as={Col} controlId={`player${index}`} className="mb-3">
            <Form.FloatingLabel label={`Player ${index + 1}`}>
              <Form.Control
                required
                id={`player${index}`}
                type="text" value={player}
                onChange={(event) => { updatePlayer(index, event.target.value) }} />
            </Form.FloatingLabel>
          </Form.Group>
        ))}
      </Row>
      <Row>
        <Col>
          <Button variant="success" type="submit" disabled={loading}>
          {loading
            ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" />
                <span> Scheduling...</span>
              </>
            )
            : (
              <span>Schedule</span>
            )
          }
          </Button>
          {submitSuccess && <span className="text-success ms-4 small">Tee time scheduled!</span>}
          {submitError && <span className="text-danger ms-4 small">Something went wrong, try again</span>}
        </Col>
      </Row>
    </Form>
  )
}

export default TeeTimeScheduler;
